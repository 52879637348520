import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import HeaderWithTenant from "../../../reports/pdf/components/headerwithtenant";
import {ResidentReportHeader} from "../../../reports/pdf/components/ResidentReportHeader";

// Define styles adjusted for single-page landscape orientation
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10, // Smaller font size to fit within one page
    fontFamily: "Helvetica",
    flexDirection: "column",
  },
  header: {
    fontSize: 14, // Smaller main header
    marginBottom: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
  calendarGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  day: {
    width: "14.28%", // 7 days in a row
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 4,
    textAlign: "left",
    height: 90, // Reduced day cell height
  },
  dayHeader: {
    width: "14.28%", // 7 days in a row
    fontWeight: "bold",
    fontSize: 8, // Smaller font for day names
    padding: 1, // Less padding for day headers
    marginBottom: 1, // Minimal margin for spacing
    textAlign: "center",
    height: 20, // Shorter header row
    backgroundColor: "#F0F0F0", // Optional visual distinction
  },
  event: {
    fontSize: 7, // Smaller font for event descriptions
    backgroundColor: "#e0e0e0",
    padding: 2,
    marginTop: 2,
    borderRadius: 2,
  },
});

// Calendar Component for PDF
export const PrintableCalendarPDF = ({
                                       events = [],
                                       month,
                                       year,
                                       resident = null,
                                       location = null,
                                       tenant = null
}) => {
  const getDaysInMonth = () => {
    const startOfMonth = moment([year, month]).startOf("month");
    const endOfMonth = moment([year, month]).endOf("month");
    const days = [];

    // Add leading empty slots for previous month's days to align the grid
    const startDayOfWeek = startOfMonth.day(); // Sunday = 0, Monday = 1
    for (let i = 0; i < startDayOfWeek; i++) {
      days.push(null); // Blank slots for days before the 1st of the month
    }

    // Add all days in the current month
    for (let day = 1; day <= endOfMonth.date(); day++) {
      days.push(moment([year, month, day]));
    }

    // Add trailing empty slots to fill the last row
    const totalSlots = Math.ceil(days.length / 7) * 7; // Ensure rows have 7 cells each
    while (days.length < totalSlots) {
      days.push(null); // Blank slots for days after the last day of the month
    }

    return days;
  };

  const renderEventsForDay = (date) => {
    return events
      .filter((event) => moment(event.start).isSame(date, "day")) // Match events only for the current date
      .map((event, index) => (
        <Text
          key={index}
          style={{
            ...styles.event,
            backgroundColor: event.color || "#e0e0e0", // Use event color or fallback to default gray
          }}
        >
          {event.title}
        </Text>
      ));
  };

  const daysInMonth = getDaysInMonth();

  return (
    <Document title="Calendar" >
      <Page size="A4" orientation="landscape" style={styles.page}>
        {location && tenant &&
          <HeaderWithTenant
          reportTitle={`${moment([year, month]).format("MMMM YYYY")} Calendar`}
          location={location}
          tenant={tenant}
          allUserLocations={false}
        />
        }
        {resident &&
          <ResidentReportHeader resident={resident}
                                location={resident?.location}
                                allUserLocations={false}
                                displayResidentInfo={true}
                                reportTitle={`${moment([year, month]).format("MMMM YYYY")} Calendar`}
          />
        }

        {/* Day Headers */}
        <View style={styles.calendarGrid}>
          {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map(
            (day, index) => (
              <Text key={index} style={styles.dayHeader}>
                {day}
              </Text>
            )
          )}
        </View>

        {/* Calendar Grid */}
        <View style={styles.calendarGrid}>
          {daysInMonth.map((date, index) => (
            <View key={index} style={styles.day}>
              {date ? (
                <>
                  <Text>{date.date()}</Text>
                  {renderEventsForDay(date)}
                </>
              ) : null}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

