import React from 'react';
import {PageTitle} from "../../../components/utils";
import ResidentAdmissions from "../../../components/resident/admissions/resident.admissions";
import {Paper} from "@material-ui/core";

export const ResidentAdmissionsPage = () => {
    return (
      <Paper>
        <PageTitle title={"Admissions | Discharges"} />
        <ResidentAdmissions />
      </Paper>
    )
}
