import React from 'react';
import {Chip, makeStyles, Step, StepLabel, Stepper, Typography} from "@material-ui/core";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  statusChip: ({ type }) => {
    const colors = {
      Admitted: theme.palette.primary,
      Admission: theme.palette.primary,
      Readmitted: theme.palette.secondary,
      Discharged: theme.palette.error,
      Transfered: theme.palette.warning,
      LOA: theme.palette.warning,
    };
    const palette = colors[type] || {};
    return {
      zIndex: 1200,
      backgroundColor: palette.dark,
      color: palette.contrastText || '#fff',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: palette.dark,
      },
    };
  },
}));

const StatusChip = ({ type, label }) => {
  const classes = useStyles({ type });
  return <Chip size="small" className={classes.statusChip} label={label} />;
};

export const AdmissionStepper = ({ admissions}) => {
  const [interactions, setInteractions] = React.useState([])

  React.useEffect(() => {
    if (admissions?.length === 1 && admissions[0]?.type === 'Admission') {
      setInteractions([...admissions, { type: 'Current', date: Date.now()}]);
    } else {
      setInteractions(admissions)
    }
  }, [admissions]);

  const admissionTypeConfig = {
    Admission: { label: "Admitted", icon: "Admitted" },
    Discharge: { label: "Discharged", icon: "Discharged" },
    Readmit: { label: "Readmitted", icon: "Readmitted" },
    Transfer: { label: "Transfered", icon: "Transfered" },
    LOA: { label: "LOA", icon: "LOA" },
    Current: { label: "Current" },
  };

  return (
    <Stepper>
      {(interactions || []).map((interaction) => {
        const typeConfig = admissionTypeConfig[interaction?.type] || {};
        return (
          <Step key={interaction?.type} alternativeLabel>
            <StepLabel StepIconComponent={() => (
              <StatusChip type={interaction?.type} label={typeConfig.label} />
            )}
            >
                <Typography variant={"subtitle2"}>
                  {interaction?.startDate
                    ? `${moment(interaction?.startDate).format("MM/DD/YYYY")} - ${moment(interaction?.endDate).format("MM/DD/YYYY")}`
                    : moment(interaction?.date).format("MM/DD/YYYY")}
                </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
