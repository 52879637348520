import apiUtil from '../utils/api.utils';
import config from '../utils/configs';
import {parseFiltersToJson} from "../utils/filter.utils";

const baseUrl = `${config.assessmentService}/api/v1.0/assessments`;
const adminBaseUrl = `${config.assessmentService}/api/v1.0/admin/assessments`;

export const getAssessments = async (
  page = 1,
  size = 25,
  filters = [],
  sort = "id",
  sortDirection = "asc"
) => {
  try {
    let url = `${baseUrl}?page=${page}&size=${size}`;
    url += parseFiltersToJson(filters);
    let sortArg = sortDirection === 'asc' ? '+' : '-';
    sortArg += sort;
    url += `&sort=${sortArg}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};


export const getAdminAssessments = async (
  page = 1,
  size = 25,
  filters = [],
  sort = "id",
  sortDirection = "asc"
) => {
  try {
    let url = `${adminBaseUrl}?page=${page}&size=${size}`;
    url += parseFiltersToJson(filters);
    let sortArg = sortDirection === 'asc' ? '+' : '-';
    sortArg += sort;
    url += `&sort=${sortArg}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
};

export const addTenantToAssessment = async (assessmentId, tenantId) => {
  try {
    const url = `${adminBaseUrl}/${assessmentId}/enabled-tenants`;
    const response = await apiUtil.client.post(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const removeTenantToAssessment = async (assessmentId, tenantId) => {
  try {
    const url = `${adminBaseUrl}/${assessmentId}/enabled-tenants`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const copyAssessment = async (assessmentId, tenantId) => {
  try {
    const url = `${adminBaseUrl}/${assessmentId}/copy`;
    const response = await apiUtil.client.post(url);
    return response.data;
  } catch (error) {
    return { error: true, message: error };
  }
}

export const getResidentAssessments = async (
  residentId,
  assessmentName,
  page = 1,
  size = 10,
  sort = "CREATED_TIMESTAMP",
  sortDirection = "desc"
) => {
  try {
    let query = `${config.assessmentService}/api/v1.0/residents/`;
    query += `${residentId}/assessments?page=${page}&size=${size}&sort=${sort},${sortDirection}`;
    if (assessmentName) {
      query += `&filter=assessmentName==${assessmentName}`;
    }
    const response = await apiUtil.client.get(query);
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

// /assessment-service/api/v1.0/residents/13173/assessments/1

export const getResidentAssessment = async (
  residentId,
  assessmentInstanceId
) => {
  const response = await apiUtil.client.get(
    `${config.assessmentService}/api/v1.0/residents/${residentId}/assessments/${assessmentInstanceId}`
  );
  return response.data;
};

export const deleteResidentAssessment = async (
  residentId,
  assessmentInstanceId
) => {
  try {
    const response = await apiUtil.client.delete(
      `${config.assessmentService}/api/v1.0/residents/${residentId}/assessments/${assessmentInstanceId}`
    );
    return response.data;
  } catch (error) {
    return { error: true, message: error }
  }
};

export const updateAssessmentInstanceAnswers = async (
  assessmentInstanceId,
  answers
) => {
  let query = `${config.assessmentService}/api/v1.0/assessmentinstances/${assessmentInstanceId}/answers`;
  const response = await apiUtil.client.put(query, answers);
  return response.data;
};

export const createResidentAssessmentInstance = async (
  residentId,
  assessmentId
) => {
  let query = `${config.assessmentService}/api/v1.0/residents/${residentId}/assessments`;
  const data = {
    residentId,
    assessmentId
  };
  const response = await apiUtil.client.post(query, data);
  return response.data;
};

export const updateAssessmentInstance = async (
  assessmentInstanceId,
  assessmentInstance
) => {
  let query = `${config.assessmentService}/api/v1.0/assessmentinstances/${assessmentInstanceId}`;
  const response = await apiUtil.client.put(query, assessmentInstance);
  return response.data;
};

export const searchResidentAssessmentsByAssessmentName = async (
  residentId,
  assessmentName = null,
  page = 1,
  size = 10,
  sort = "CREATED_TIMESTAMP",
  sortDirection = "desc"
) => {
  try {
    const response = await getResidentAssessments(
      residentId,
      assessmentName,
      page,
      size,
      sort,
      sortDirection
    );
    return response;
  } catch (err) {
    return {error: true, message: err};
  }
};

export const createResidentAssessment = async (residentId, assessmentId) => {
  try {
    const data = {
      residentId,
      assessmentId
    };
    const response = await apiUtil.client.post(`${config.assessmentService}/api/v1.0/residents/${residentId}/assessments`, data);
    return response.data;
  } catch (error) {
    alert(error);
    return;
  }
};

export const setAssessmentInstanceAnswers = async (
  assessmentInstanceId,
  questions
) => {
  try {
    const answers = questions.map(question => ({ id: question.id, value: question.value }))
    let query = `${config.assessmentService}/api/v1.0/assessmentinstances/${assessmentInstanceId}/answers`;
    const response = await apiUtil.client.put(query, answers);
    return response.data;
  } catch (error) {
    alert(error);
    return null;
  }
};


export const updateAssessmentQuestionGroup = async (residentId, assessmentId, questionGroupId, questionGroup) => {
  try {
    const data = questionGroup.questions.map(question => ({ _id: question._id, value: question.value }));
    const url = `${config.assessmentService}/api/v1.0/residents/${residentId}/assessments/${assessmentId}/questiongroups/${questionGroupId}`;
    const response = await apiUtil.client.put(url, data);
    return response.data;
  } catch (err) {
    alert(err);
    return null;
  }
}

export const completeResidentAssessment = async (residentId, assessmentId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/residents/${residentId}/assessments/${assessmentId}`;
    const response = await apiUtil.client.put(url, { isComplete: true, completedAt: new Date() });
    return response.data;
    //const result = await
  } catch (err) {
    return false;
  }
}

export const reopenResidentAssessment = async (residentId, assessmentId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/residents/${residentId}/assessments/${assessmentId}`;
    const response = await apiUtil.client.put(url, { isComplete: false, completedAt: null });
    return response.data;
    //const result = await
  } catch (err) {
    return false;
  }
}


export const createAssessment = async assessment => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments`;
    const response = await apiUtil.client.post(url, assessment);
    return response.data;
  } catch (err) {
    return { error: true, message: err };
  }
}

export const getAssessment = async id => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${id}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const updateAssessment = async (id, assessment) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${id}`;
    const response = await apiUtil.client.put(url, {
      ...assessment,
      isGlobal: assessment.isGlobal === 'Yes' || assessment.isGlobal === true || assessment?.isGlobal === 'true',
    });
    return response.data;
  } catch (err) {
    return null;
  }
}


export const deleteAssessment = async (id) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${id}`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (err) {
    return null;
  }
}


export const getAssessmentTypes = async id => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessmenttypes`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getAssessmentType = async id => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessmenttypes/${id}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const createAssessmentType = async assessmentType => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessmenttypes`;
    const response = await apiUtil.client.post(url, assessmentType);
    return response.data;
  } catch (err) {
    return false;
  }
}

export const updateAssessmentType = async (id, type) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessmenttypess/${id}`;
    const response = await apiUtil.client.put(url, type);
    return response.data;
  } catch (err) {
    return null;
  }
}


export const deleteAssessmentType = async (id) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessmenttypes/${id}`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getQuestions = async id => {
  try {
    const url = `${config.assessmentService}/api/v1.0/questions`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getQuestion = async id => {
  try {
    const url = `${config.assessmentService}/api/v1.0/questions/${id}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const createQuestion = async question => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessmenttypes`;
    const response = await apiUtil.client.post(url, question);
    return response.data;
  } catch (err) {
    return false;
  }
}

export const updateQuestion = async (id, question) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/question/${id}`;
    const response = await apiUtil.client.put(url, question);
    return response.data;
  } catch (err) {
    return null;
  }
}


export const deleteQuestion = async (id) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/questions/${id}`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const createAssessmentSection = async (assessmentId, section) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections`;
    const response = await apiUtil.client.post(url, section);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getAssessmentSections = async (assessmentId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getAssessmentSection = async (assessmentId, sectionId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const updateAssessmentSection = async (assessmentId, sectionId, section) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}`;
    const response = await apiUtil.client.put(url, section);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const deleteAssessmentSection = async (assessmentId, sectionId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const orderSection = async (assessmentId, sectionId, oldSortOrder, newSortOrder) => {
  try {
    const payload = { newSortOrder, oldSortOrder };
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/sortorder`;
    const response = await apiUtil.client.put(url, payload);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getQuestionTypes = async () => {
  try {
    const url = `${config.assessmentService}/api/v1.0/questiontypes`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const createSectionQuestion = async (assessmentId, sectionId, question) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions`;
    const response = await apiUtil.client.post(url, question);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getSectionQuestions = async (assessmentId, sectionId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getSectionQuestion = async (assessmentId, sectionId, questionId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}`;
    const response = await apiUtil.client.get(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const updateSectionQuestion = async (assessmentId, sectionId, questionId, question) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}`;
    const response = await apiUtil.client.put(url, question);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const deleteSectionQuestion = async (assessmentId, sectionId, questionId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const orderSectionQuestion = async (assessmentId, sectionId, questionId, payload) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}/sortorder`;
    const response = await apiUtil.client.put(url, payload);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const createSectionQuestionOption = async (assessmentId, sectionId, questionId, payload) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}/options`;
    const response = await apiUtil.client.post(url, payload);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const updateSectionQuestionOption = async (assessmentId, sectionId, questionId, optionId, payload) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}/options/${optionId}`;
    const response = await apiUtil.client.put(url, payload);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const deleteSectionQuestionOption = async (assessmentId, sectionId, questionId, optionId) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}/options/${optionId}`;
    const response = await apiUtil.client.delete(url);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const orderSectionQuestionOption = async (assessmentId, sectionId, questionId, optionId, payload) => {
  try {
    const url = `${config.assessmentService}/api/v1.0/assessments/${assessmentId}/sections/${sectionId}/questions/${questionId}/options/${optionId}/sortorder`;
    const response = await apiUtil.client.put(url, payload);
    return response.data;
  } catch (err) {
    return null;
  }
}

export const getAssessmentInstancePdf = async (residentId, assessmentInstanceId) => {
  try {
    const url = `${config.reportService}/api/v1.0/generate/reports/residents/${residentId}/assessments/${assessmentInstanceId}`;
    const response = await apiUtil.client.get(url, { responseType: 'blob'});
    return response?.data;
  } catch (err) {
    return {error: true, message: err};
  }
}
