import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {ResidentContext} from "../../../context/residentcontext";
import moment from "moment";
import {
  createResidentAdmission,
  deleteResidentAdmission,
  updateResidentAdmission
} from "../../../services/admission.service";
import {GlobalNotificationContext} from "../../notification/globalnotificationprovider";
import {getResidentStatuses} from "../../../services/resident.service";
import AddIcon from "@material-ui/icons/Add";
import CreateInteraction from "./create.interaction";
import {DeleteForeverTwoTone, EditTwoTone } from "@material-ui/icons";
import EditInteraction from "./edit.interaction";
import {AdmissionStepper} from "./AdmissionStepper";
import useHasRole from "../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1)
  },
  createBtn: {
    margin: theme.spacing(0, 1, 0, 1)
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  }
}))



const ResidentAdmissions = () => {
  const classes = useStyles();
  const {resident, refresh} = React.useContext(ResidentContext);
  const [residentAdmissions, setResidentAdmissions] = React.useState([]);
  const {addNotification} = React.useContext(GlobalNotificationContext);
  const [residentStatuses, setResidentStatuses] = React.useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [deleteAdmissionId, setDeleteAdmissionId] = React.useState();
  const [showCreateInteractionModal, setShowCreateInteractionModal] = React.useState(false);
  const [viewInteractionModal, setViewInteractionModel] = React.useState(false);
  const [editAdmission, setEditAdmission] = React.useState();
  const {checkPermission} = useHasRole()
  const canUpdateResident = checkPermission('RESIDENT_UPDATE');


  React.useEffect(() => {
    const setUp = async () => {
      if (!residentStatuses || residentStatuses?.length === 0) {
        const statuses = await getResidentStatuses();
        setResidentStatuses(statuses);
      }
    }
    if (resident) {
      setResidentAdmissions(resident?.admissions ?? []);
      setUp();
    }
    // eslint-disable-next-line
  }, [resident]);

  const onInteractionCreated = async interaction => {
    const result = await createResidentAdmission(resident?._id, interaction);
    if (!result?.error) {
      const obj = [...residentAdmissions, result];
      setResidentAdmissions(obj);
      setShowCreateInteractionModal(false);
      refresh();
    } else {
      addNotification(result?.message);
    }
  }

  const onDeleteAdmission = (admissionId) => {
    setDeleteAdmissionId(admissionId);
    setShowConfirmDelete(true);
  }

  const onDeleteAdmissionConfirmed = async () => {
    const response = await deleteResidentAdmission(resident?._id, deleteAdmissionId);
    if (response?.ok) {
      const obj = residentAdmissions?.filter(ra => ra?._id !== deleteAdmissionId);
      setResidentAdmissions(obj);
      addNotification("Admission deleted", "success");
      setDeleteAdmissionId(null);
      setShowConfirmDelete(false);
      refresh();
    } else {
      addNotification("Unable to delete admission record");
    }
  }

  const onEditInteraction = admission => {
    setEditAdmission(admission);
    setViewInteractionModel(true);
  }

  const onUpdateAdmission = async admission => {
    const result = await updateResidentAdmission(resident?._id, editAdmission?._id, admission);
    if (!result?.error) {
      const obj = residentAdmissions?.map(ra => ra?._id === result?._id ? result: ra);
      setResidentAdmissions(obj);
      addNotification("Admission updated", "success");
      setEditAdmission(null);
      setViewInteractionModel(false);
      refresh();
    } else {
      addNotification("Unable to update admission record");
    }
  }


  return (
    <>
      <AdmissionStepper admissions={residentAdmissions} />
      <TableContainer>
        <Table size={"small"}
               stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                {canUpdateResident &&
                <Button startIcon={<AddIcon />}
                        onClick={() => setShowCreateInteractionModal(true)}
                >Add</Button>
                }
                <CreateInteraction open={showCreateInteractionModal}
                                   onClose={() => setShowCreateInteractionModal(false)}
                                   onSave={onInteractionCreated}
                />
                <EditInteraction open={viewInteractionModal}
                                 onClose={() => setViewInteractionModel(false)}
                                 interaction={editAdmission}
                                 onSave={onUpdateAdmission}
                                 canEdit={canUpdateResident}

                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={250} />
              <TableCell>Interaction</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {residentAdmissions?.map(residentAdmission =>
            <TableRow>
              <TableCell>
                <Button startIcon={<EditTwoTone />}
                        size={"small"}
                        onClick={() => onEditInteraction(residentAdmission)}
                >
                  View
                </Button>
                {canUpdateResident &&
                <Button startIcon={<DeleteForeverTwoTone />}
                        className={classes.deleteBtn}
                        size={"small"}
                        onClick={() => onDeleteAdmission(residentAdmission?._id)}
                >
                  Delete
                </Button>
                }
              </TableCell>
              <TableCell>
                {residentAdmission?.type ?? 'n/a'}
              </TableCell>
              <TableCell>
                {residentAdmission?.type === 'LOA' &&
                <>
                <Typography variant={"subtitle2"}>
                  Start: {moment(residentAdmission?.startDate).format("MM/DD/YYYY")}
                </Typography>
                <Typography variant={"subtitle2"}>
                  End: {moment(residentAdmission?.endDate).format("MM/DD/YYYY")}
                </Typography>
                </>
                }
                {residentAdmission?.type !== 'LOA' &&
                <span>
                {moment(residentAdmission?.date).format("MM/DD/YYYY hh:mm a")}</span>
                }
              </TableCell>
              <TableCell>
                {residentAdmission?.reason}
              </TableCell>
              <TableCell>
                {residentAdmission?.comment}
              </TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showConfirmDelete}
              onClose={() => setShowConfirmDelete(false)}
      >
        <DialogTitle>
          {"Delete Admission?"}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{"Are you sure you want to delete this admission record?"}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={"primary"}
                  variant={"text"}
                  onClick={() => setShowConfirmDelete(false)}
          >
            Cancel
          </Button>
          <Button color={"primary"}
                  variant={"contained"}
                  onClick={onDeleteAdmissionConfirmed}
                  className={classes.deleteBtn}
                  startIcon={<DeleteForeverTwoTone />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResidentAdmissions;
