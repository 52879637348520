import React from 'react';
import { getResidentById } from '../services/resident.service';
import {NavigationContext} from "./NavigationContext";

let ResidentContext = React.createContext();
let initialState = {
  error: false,
  resident: {},
  loadingResident: true,
  loadingAlerts: false,
  refreshAlerts: false,
};
let reducer = (state, action) => {
  switch (action.type) {
    case 'GET_RESIDENT_START':
      return { ...state, resident: {}, loadingResident: true, error: false };
    case 'GET_RESIDENT_COMPLETE':
      return { ...state, resident: action.payload, loadingResident: false, error: false };
    case 'GET_RESIDENT_ERROR_COMPLETE':
      return { ...state, resident: null, loadingResident: false, error: true };
    case 'INITAL_ALERT_LOAD':
      return { ...state, refreshAlerts: false, loadingAlerts: true };
    case 'REFRESH_ALERTS':
      return { ...state, refreshAlerts: true, loadingAlerts: true };
    case 'REFRESH_ALERTS_COMPLETE':
      return { ...state, refreshAlerts: false, loadingAlerts: false };
    case 'UPDATE_RESIDENT_START':
      return { ...state, loadingResident: true };
    case 'UPDATE_RESIDENT_COMPLETE':
      return { ...state, resident: action.payload, loadingResident: false };
    case 'UPDATE_RESIDENT_ALLERGY':
      const obj = {...state.resident};
      obj.allergies = action.payload;
      return { ...state, resident: obj, loadingResident: false };
    case 'RESIDENT_TESTS_UPDATED':
      const testObj = {...state.resident};
      testObj.tests = action.payload;
      return { ...state, resident: testObj, loadingResident: false };
    case 'RESIDENT_VACCINATIONS_UPDATED':
      const vacObj = {...state.resident};
      vacObj.vaccinations = action.payload;
      return { ...state, resident: vacObj, loadingResident: false };
    case 'RESIDENT_DIAGNOSIS_UPDATED':
      const diagObj = {...state.resident};
      diagObj.diagnosis = action.payload;
      return { ...state, resident: diagObj, loadingResident: false };
    case 'ADD_RESIDENT_PHYSICIAN':
      return {
        ...state,
        resident: {...state?.resident, residentPhysicians: [ ...state?.resident?.residentPhysicians, action.payload ]}
      };
    case 'UPDATE_RESIDENT_PHYSICIAN':
      const residentObj = {...state.resident};
      residentObj.residentPhysicians = residentObj?.residentPhysicians.map(rp => rp._id === action?.payload?._id ? action.payload: rp);
      return {
        ...state,
        resident: residentObj
      };
    case 'DELETE_RESIDENT_PHYSICIAN':
      return {
        ...state,
        resident: {
          ...state?.resident,
          residentPhysicians:  state?.resident?.residentPhysicians?.filter(
            rp => rp?._id !== action?.payload?._id)
        }
      };
    case 'ADD_RESIDENT_PHARMACY': {
      return {
        ...state,
        resident: {...state.resident, residentPharmacies: [ ...state?.resident?.residentPharmacies, action.payload ]
        }
      };
    }
    case 'UPDATE_RESIDENT_PHARMACY': {

      const obj = {
        ...state?.resident,
        residentPharmacies: state?.resident?.residentPharmacies.map(rp => rp?._id === action?.payload?._id ? action.payload: rp)
      }
      return {
        ...state,
        resident: obj
      };
    }
    case 'DELETE_RESIDENT_PHARMACY': {
      const deletePharmacyObj = {...state?.resident};
      deletePharmacyObj.residentPharmacies = state?.resident?.residentPharmacies.filter(rp => rp?.pharmacy?._id !== action?.payload);
       return {
        ...state,
        resident: deletePharmacyObj
      };
    }
    case 'SET_RESIDENT':
      return { ...state, resident: action.payload };
    default:
      return { ...state };
  }
};
const ResidentContextProvider = ({ residentId, children }) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let { setResident } = React.useContext(NavigationContext)

  React.useEffect(() => {
    if (residentId) {
      loadResident(residentId);
    }
    // eslint-disable-next-line
  }, [residentId]);

  const refresh = async () => {
    await loadResident(residentId);
  }

  let value = { state, dispatch, resident: state.resident, loadingResident: state.loadingResident, refresh };

  const loadResident = async (id) => {
    dispatch({ type: 'GET_RESIDENT_START' });
    const response = await getResidentById(id);
    if (!response?.error) {
      setResident(response);
      dispatch({ type: 'GET_RESIDENT_COMPLETE', payload: response });
    } else {
      dispatch({ type: 'GET_RESIDENT_ERROR_COMPLETE', payload: response });
    }
  };

  return (
    <ResidentContext.Provider value={value}>
      {children}
    </ResidentContext.Provider>
  );
};

let ResidentContextConsumer = ResidentContext.Consumer;

export { ResidentContext, ResidentContextProvider, ResidentContextConsumer };
