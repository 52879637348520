import React from 'react';
import {AssessmentsContext} from "./assessments.context";
import {FormFieldsGenerator, isModelValid} from "../../utils/forms";
import {Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select} from "@material-ui/core";
import {updateAssessmentAction} from "./assessment.actions";
import { tenantModel, globalModel } from './assessment.model';
import {getTenants} from "../../services/tenant.service";
import {UserContext} from "../../context/usercontext";

const AssessmentInfo = ({canUpdate}) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(tenantModel)));
  const { state, dispatch } = React.useContext(AssessmentsContext);
  const {user} = React.useContext(UserContext);

  const resetForm = () => {
    mergeValues(state?.assessment);
  }

  React.useEffect(() => {
    if (state?.assessment) {
      mergeValues(state.assessment);
    }
    // eslint-disable-next-line
  }, [state.assessment]);

  const mergeValues = assessment => {
    let obj = JSON.parse(JSON.stringify(tenantModel));
    Object.keys(state.assessment).forEach(key => {
      if (obj.hasOwnProperty(key)) {
        obj[key].value = state.assessment[key]
      }
    });
    obj.type.dropDownItems = state.types;
    setModel(obj);
  }

  const onChange = e => {
    const obj = {...model, ...e};
    setModel(obj);
  }

  const save = async () => {
    let data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });
    await updateAssessmentAction(state?.assessment?._id, data, dispatch);
  }

  const checkIfValid = () => {
    if (!isModelValid(model)) {
      return false;
    }
    if (user?.isSystemAdmin) {
      if (model?.isGlobal?.value === true) {
        return true;
      } else if (model?.tenant?.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return isModelValid(model);
    }
  }

  return (
    <div style={{width: '100%'}}>
      {state.updatingAssessmentSummary && <LinearProgress />}
      <FormFieldsGenerator model={model} onChange={onChange} edit={canUpdate} />
      {canUpdate &&
      <Grid style={{marginTop: '1rem'}}
            container
            justify={"flex-end"}>
        <Button color={"primary"}
                onClick={resetForm}>Cancel</Button>
        <Button color={"primary"}
                onClick={save}
                disabled={!checkIfValid()}
                variant={"contained"}>
          Save
        </Button>
      </Grid>}
    </div>
  )
}

const GlobalAssessmentInfo = ({canUpdate, assessment}) => {
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(globalModel)));
  const { state, dispatch } = React.useContext(AssessmentsContext);
  const [tenants, setTenants] = React.useState([]);

  React.useEffect(() => {
    if (state?.assessment) {
      mergeValues(state?.assessment);
    }
    // eslint-disable-next-line
  },[state?.assessment]);

  const resetForm = () => {
    mergeValues(state?.assessment);
  }

  const mergeValues = async assessment => {
    let obj = JSON.parse(JSON.stringify(globalModel));
    Object.keys(state.assessment).forEach(key => {
      if (obj.hasOwnProperty(key)) {
        if (key === "isGlobal") {
          obj.isGlobal.value = state.assessment.isGlobal ? "Yes": "No";
        } else {
          obj[key].value = state.assessment[key]
        }
      }
    });
    if (!tenants || tenants?.length === 0) {
      const response = await getTenants();
      setTenants(response);
      obj.tenant.dropDownItems = response;
    } else {
      obj.tenant.dropDownItems = tenants;
    }
    obj.type.dropDownItems = state.types;
    setModel(obj);
  }

  const save = async () => {
    let data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });
    await updateAssessmentAction(state?.assessment?._id, data, dispatch);
  }

  const onChange = e => {
    const obj = {...model, ...e};
    if (obj?.isGlobal?.value === true) {
      obj.tenant.value = null;
    }
    setModel(obj);
  }

  const onTenantChange = e => {
    const obj = {...model};
    obj.tenant.value = e.target.value;
    setModel(obj);
  }

  const checkIfValid = () => {
    return isModelValid(model) && (
      (model?.isGlobal.value === true || model?.isGlobal?.value === "Yes")
      ||
      ((model?.isGlobal?.value === false || model?.isGlobal?.value === "No") && model?.tenant?.value)
    );
  }


  return (
    <div style={{width: '100%'}}>
      {state.updatingAssessmentSummary && <LinearProgress/>}
      <FormFieldsGenerator model={model} onChange={onChange} edit={canUpdate}/>
      {!model?.isGlobal?.value &&
        <FormControl fullWidth>
          <InputLabel shrink>Tenant</InputLabel>
          <Select fullWidth
                  value={model?.tenant?.value}
                  label={"Tenant"}
                  displayEmpty
                  onChange={onTenantChange}

          >
            <MenuItem>Select One</MenuItem>
            {model?.tenant?.dropDownItems?.map(item =>
              <MenuItem value={item._id}>{item.name}</MenuItem>
            )}
          </Select>
        </FormControl>
      }
      {canUpdate &&
        <Grid style={{marginTop: '1rem'}}
              container
              justifyContent={"flex-end"}>
          <Button color={"primary"}
                  onClick={resetForm}
          >Cancel</Button>
          <Button color={"primary"}
                  onClick={save}
                  disabled={!checkIfValid()}
                  variant={"contained"}>
            Save
          </Button>
        </Grid>}
    </div>
  )
}

const InfoWrapper = ({canUpdate}) => {
  const {user} = React.useContext(UserContext);

  if (user?.isSystemAdmin) {
    return <GlobalAssessmentInfo canUpdate={canUpdate} />
  } else {
    return <AssessmentInfo canUpdate={canUpdate} />
  }

}

export default InfoWrapper;
