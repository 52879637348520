import {Calendar, momentLocalizer } from "react-big-calendar";
import React from "react";
import moment from 'moment';
import {makeStyles} from "@material-ui/core";
// import {PrintableCalendar} from "./print/Calendar";
import {CustomToolbar} from "./custom.toolbar";
import {CalendarPrintDialog} from "./print/calendar.print.dialog";


const localizer = momentLocalizer(moment)

const useStyles = makeStyles((theme) => ({
  loading: {
    opacity: '.2'
  }
}));



export const SsaCalendar = ({
                              events,
                              loadingEvents,
                              height = 500,
                              color = "#fff",
                              slotSelected,
                              eventSelected,
                              eventPropertySetter = null,
                              customEventComponent,
                              weekEventComponent,
                              customAgendaEventComponent,
                              onTimeframeNavigated,
                              onView,
                              resident = null,
                              tenant = null,
                              location = null,
                              ...p }) => {
  const [viewPrintModal, setViewPrintModal] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const classes = useStyles();



  const onNavigate = (date, timeframe, action) => {
    setSelectedDate(date);
    const data = {
      date,
      timeframe,
      action
    };
    if (onTimeframeNavigated) {
      onTimeframeNavigated(data);
    }
  }

  const onViewChanged = view => {
    if (onView) {
      onView(view);
    }
  }

  const handlePrint = () => {
    setViewPrintModal(true);
  }

  return (
    <div className={loadingEvents ? classes.loading : null}>
      <CalendarPrintDialog open={viewPrintModal}
                           selectedDate={selectedDate}
                           events={events}
                           date={selectedDate}
                           resident={resident}
                           tenant={tenant}
                           location={location}
                           onClose={() => setViewPrintModal(false)} />

      <Calendar
        onNavigate={onNavigate}
        onView={onViewChanged}
        selectable
        localizer={localizer}
        events={events}
        style={{height}}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventPropertySetter}
        onSelectSlot={slotSelected}
        onSelectEvent={eventSelected}
        views={{agenda: true, month: true, week: true, day: true}}
        components={{
          toolbar: (props) =>
          <CustomToolbar onPrint={handlePrint} {...props} />
          ,
        }}
        {...p}
      />

    </div>
  )
}
