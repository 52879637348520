import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import ResidentPage from './resident.page';
import withWidth from '@material-ui/core/withWidth';
import ResidentEmar from '../medicationorders/emar/resident.emar';
import ResidentMedicationOrders from '../medicationorders/resident.medicationorders';
import MedicationOrder from '../../medicationorders/medicationorder';
import ResidentCard from '../card/resident.card';
import ResidentMar from '../medicationorders/mar/resident.mar';
import ResidentVitals from '../vitals/resident.vitals';
import ResidentEpnList from '../medicationorders/eprn/resident.eprn.list';
import { Eprn } from '../medicationorders/eprn/page';
import { ResidentContextProvider } from '../../../context/residentcontext';
import ResidentTreatments from '../treatments/resident.treatment';
import ResidentTars from '../treatments/tar/resident.tars';
import ResidentLogs from '../logs/resident.logs';
import ResidentDetails from '../details/resident.details';
import ResidentMedical from '../medical/resident.medical';
import ResidentBackground from '../background/resident.background';
import ResidentAddress from '../address/resident.address';
import ResidentPhonePage from '../phones/resident.phone.page';
import ResidentPharmaciesPage from '../pharmacies/resident.pharmacies.page';
import ResidentPhysiciansPage from '../physicians/resident.pharmacies.page';
import ResidentContactsPage from '../contacts/resident.contacts.page';
import ResidentInsurancesPage from '../insurances/resident.insurances.page';
import ResidentCareplans from '../careplan/resident.careplans';
import ResidentTasks from '../task/resident.tasks';
import {ResidentTaskLog} from '../task/log';
import ResidentDiets from '../diet/resident.diets';
import ResidentEdars from '../diet/edar/resident.edars';
import ResidentNotes from "../notes/resident.notes";
import ResidentMedicalProviders from "../medicationProviders/resident.medication.providers";
import ResidentAllergies from "../allergy/resident.allergy";
import ResidentDiagnosis from "../diagnosis/resident.diagnosis";
import ResidentNotePage from "../notes/resident.note.page";
import ResidentContentNotFound from "../errors/notfound";
import ResidentAssessmentsList from '../assessment/resident.assessments.list';
import ResidentAssessment from "../assessment/ResidentAssessment";
// import ResidentAdmissionsPage from "../admissions/resident.admissions.page";
import { ResidentAdmissionsPage } from '../../../pages/residents/admissions';
import {makeStyles} from "@material-ui/core";
import {ResidentCalendar} from "../calendar";
import {ResidentTreatmentLog} from"../treatments/tar/log"
import {VitalsPrint} from "../vitals/vitals/print";
import {EprnMARPage} from "../medicationorders/eprn/mar";
import {ResidentFiles} from "../files";
// import {Alert, AlertTitle} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: '8rem'
  },
  cardHolder: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  residentContentContainer: {
    padding: theme.spacing(0, 0, 5, 0),
    overflow: 'scroll',
    height: '77vh',
  }
}))

function Resident() {
  const classes = useStyles();
  const { residentId } = useParams();

  return (
    <ResidentContextProvider residentId={residentId}>
      <div className={classes.root}>
        <div className={classes.cardHolder}>
          <ResidentCard />
        </div>
        {/*<div style={{marginTop: '.5vh', border: '1px solid black'}}>*/}
        {/*  <Alert severity={"error"}>*/}
        {/*    <AlertTitle>PRN Recheck Alerts</AlertTitle>*/}
        {/*    This resident has PRN Recheck alerts, click <strong>here</strong> to view.*/}
        {/*  </Alert>*/}
        {/*</div>*/}
        <div className={classes.residentContentContainer}>
          <Switch>
            <PrivateRoute exact path="/residents/:id" component={ResidentPage} />
            <PrivateRoute
              exact
              path="/residents/:id/emar"
              component={ResidentEmar}
            />
            <PrivateRoute
              exact
              path="/residents/:id/evitals"
              component={ResidentVitals}
            />
            <PrivateRoute
              exact
              path={"/residents/:id/evitals/log"}
              component={VitalsPrint}
              />
            <PrivateRoute
              exact
              path="/residents/:id/medications"
              component={ResidentMedicationOrders}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/medications/:id"
              component={MedicationOrder}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/mar"
              component={ResidentMar}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/eprns"
              component={ResidentEpnList}
            />
            <PrivateRoute
              exact
              path={'/residents/:residentId/eprns/mar'}
              component={EprnMARPage}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/eprns/:id"}
              component={Eprn}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/treatments"
              component={ResidentTreatments}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/etar"
              component={ResidentTars}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/elogs"
              component={ResidentLogs}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/details"
              component={ResidentDetails}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/medical"
              component={ResidentMedical}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/medicalproviders"
              component={ResidentMedicalProviders}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/allergies"
              component={ResidentAllergies}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/diagnosis"}
              component={ResidentDiagnosis} />
            <PrivateRoute
              exact
              path="/residents/:residentId/background"
              component={ResidentBackground}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/address"
              component={ResidentAddress}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/phones"
              component={ResidentPhonePage}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/pharmacies"
              component={ResidentPharmaciesPage}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/physicians"
              component={ResidentPhysiciansPage}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/contacts"
              component={ResidentContactsPage}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/insurance"
              component={ResidentInsurancesPage}
            />
            <PrivateRoute
              path="/residents/:residentId/careplans"
              component={ResidentCareplans}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/etar/log"}
              component={ResidentTreatmentLog}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/tasks"
              component={ResidentTasks}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/tasks/log"}
              component={ResidentTaskLog}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/diets"
              component={ResidentDiets}
            />
            <PrivateRoute
              exact
              path="/residents/:residentId/edar"
              component={ResidentEdars}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/admissions"}
              component={ResidentAdmissionsPage}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/notes"}
              component={ResidentNotes} />
            <PrivateRoute
              exact
              path={"/residents/:residentId/notes/:residentNoteId"}
              component={ResidentNotePage}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/files"}
              component={ResidentFiles}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/assessments"}
              component={ResidentAssessmentsList}
              />
            <PrivateRoute
              exact
              path={"/residents/:residentId/assessments/:assessmentId"}
              component={ResidentAssessment}
            />
            <PrivateRoute
              exact
              path={"/residents/:residentId/calendar"}
              component={ResidentCalendar}
            />

            <PrivateRoute
              path={"/residents/:residentId"}
              component={ResidentContentNotFound} />
          </Switch>
        </div>
      </div>
    </ResidentContextProvider>
  );
}

export default withWidth()(Resident);
