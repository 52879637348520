import React from "react";
import {Button, ButtonGroup} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

// Custom Toolbar for react-big-calendar
export const CustomToolbar = ({ label, onNavigate, onView, onPrint }) => {
  return (
    <div style={{ padding: "5px 0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {/* Navigation Buttons */}
      <ButtonGroup size="small" variant="outlined" aria-label="small outlined button group">
        <Button onClick={() => onNavigate("TODAY")}>
          Today
        </Button>
        <Button onClick={() => onNavigate("PREV")}>
          Back
        </Button>
        <Button onClick={() => onNavigate("NEXT")}>
          Next
        </Button>
      </ButtonGroup>

      {/* Calendar View Title */}
      <div>
        <h3>{label}</h3>
      </div>

      {/* View Change Buttons */}
      <ButtonGroup size="small" variant="outlined" aria-label="small outlined button group">
        <Button startIcon={<PictureAsPdfIcon />} onClick={onPrint}>Print</Button>
        <Button onClick={() => onView("agenda")}>Agenda</Button>
        <Button onClick={() => onView("month")}>Month</Button>
        <Button onClick={() => onView("week")}>Week</Button>
        <Button onClick={() => onView("day")}>Day</Button>
      </ButtonGroup>
    </div>
  );
};
