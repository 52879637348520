import React from 'react';
import {useParams} from "react-router-dom";
import {getTreatmentEventsForMonth} from "../../../../../services/treatment.service";
import {
  Paper,
  makeStyles,
  TextField,
  MenuItem,
  FormGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow, TableCell, TableBody, Grid, Typography, Button
} from "@material-ui/core";
import {PageTitle} from "../../../../utils";
import Months from "../../../medicationorders/mar/month.values";
import moment from 'moment-timezone';
import * as _ from 'lodash';
import TableLoadingIndicator from "../../../../../utils/indicators/table.loading";
import {getStripedStyle} from "../../../../utils/table.utils";
import {AssignmentLateRounded, CheckCircle} from "@material-ui/icons";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {ResidentContext} from "../../../../../context/residentcontext";
import {PrintTreatmentLogDiaglog} from "./print/PrintTreatmentLogDialog";
import { ETarLogItem } from './ETarLogItem';
import {getTenantUsers} from "../../../../../services/tenant.service";
import {UserContext} from "../../../../../context/usercontext";
import useHasRole from "../../../../../hooks/user.role.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(.5, 0)
  },
  tableDayColumn: {
    minWidth: "65px",
    maxWidth: "65px",
    textAlign: "center",
    borderLeft: "1px solid gray",
    borderRight: "1px solid gray",
  },
  legendContainer: {
    padding: theme.spacing(1)
  },
  legendItem: {
    display: 'flex',
    padding: theme.spacing(0, .1)
  },
  legendValue: {
    margin: theme.spacing(0, .2)
  },
  late: {
    color: theme.palette.warning.main
  },
  eventTemplateContainer: {

  },
  hrHeader: {
    minWidth: 125
  },
  prnAlertContainer: {
    padding: theme.spacing(1),
    width: '100%',
    backgroundColor: theme.palette.grey[200]
  },
}))

export const ResidentTreatmentLog = () => {
  const classes = useStyles();
  const {selectedTenant} = React.useContext(UserContext)
  const [months] = React.useState(Months);
  const [year, setYear] = React.useState();
  const [years, setYears] = React.useState();
  const [month, setMonth] = React.useState();
  const [users, setUsers] = React.useState();
  const [viewPrintModal, setViewPrintModal] = React.useState(false);
  const [days, setDays] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { residentId } = useParams();
  const [treatments, setTreatments] = React.useState();
  const { resident } = React.useContext(ResidentContext);
  const { checkPermission } = useHasRole();
  const isReportPrinter = checkPermission("REPORT_PRINTER");

  React.useEffect(() => {
    if (selectedTenant && residentId) {
      generateYears(moment().get("year") + 1);
      setMonth(moment().get('month') + 1);
      setYear(moment().get('year'));
      init(residentId, moment().get('year'), moment().get('month') + 1, selectedTenant)
    }
    // eslint-disable-next-line
  }, [residentId, selectedTenant]);

  const init = async (residentId, year, month, selectedTenant) => {
    const userResponse = await getTenantUsers(selectedTenant?._id);
    if (!userResponse?.error) {
      setUsers(userResponse);
    }
    await getData(residentId, year, month)
  }


  const generateYears = (cur) => {
    const obj = [];
    for (let min = cur - 7; min <= cur; min++) {
      obj.push(min);
    }
    setYears(obj);
  };

  const getData = async (residentId, year, month) => {
    setLoading(true);
    setError(false);
    const date = moment().set({ year: year, month: month - 1 });
    const daysInMonth = date.daysInMonth();
    setDays(daysInMonth)
    const response = await getTreatmentEventsForMonth(residentId, year, month);
    if (!response?.error) {
      setTreatments(response);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const changeYear = async (e) => {
    setYear(e.target.value);
    await getData(residentId, e.target.value, month);
  };

  const changeMonth = async (e) => {
    setMonth(e.target.value);
    await getData(residentId, year, e.target.value);
  };

  const getHR = (eventTemplate, frequency) => {
    switch (frequency?.name) {
      case "QH":
      case "Q2H":
      case "Q3H":
      case "Q4H":
      case "Q6H":
      case "Q12H":
        return frequency.description;
      default:
        const date = moment({hour: eventTemplate?.hour, minute: eventTemplate?.minute});
        return `${date?.format("h:mm a")}`;
    }
  }


  const onETarUpdated = (eTarUpdatedEvent) => {
    const obj = treatments?.map(treatment => {
      treatment.events = treatment.events?.map(event =>
        event?._id === eTarUpdatedEvent?._id ? eTarUpdatedEvent?.payload: event);
      return treatment;
    });
    setTreatments(obj);
  }

  const getPrnValue = (treatment, day) => {
    const events = treatment?.events?.filter(event =>
      moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").date() === +day
    );
    if (events?.length === 1) {
      const event = events[0];
      return (
        <ETarLogItem eTar={event}
                     residentId={treatment?.resident?._id}
                     treatment={treatment}
                     onUpdated={onETarUpdated}
                     users={users}
        />
      )
    } else if (events?.length > 1) {
      return (
        <div style={{textAlign: "center"}}>
          <div>B</div>
          <div>{events[0]?.status?.name}</div>
          <div>{events[0]?.administeredUser?.initials}</div>
        </div>
      )
    } else {
      return "X";
    }
  }

  const getValue = (treatment, eventTemplate, day) => {
    const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
    let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(treatment?.frequency?.name) > -1
    let events = [];
    if (isEveryXHoursFrequency) {
      events = treatment?.events?.filter(event =>
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").date() === +day
      );
    } else {
      events = treatment?.events?.filter(event =>
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").date() === +day &&
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").hour() === +eventTemplate.hour &&
          moment.tz(event.scheduledTime, treatment?.resident?.location?.timeZone ?? "US/Central").minute() === +eventTemplate.minute
      );
    }

    if (events?.length > 0) {
      return events?.map(event =>
          <ETarLogItem eTar={event}
                       residentId={treatment?.resident?._id}
                       treatment={treatment}
                       onUpdated={onETarUpdated}
                       users={users}
                       isEveryXHoursFrequency={isEveryXHoursFrequency}
          />)

    } else {
      return "X";
    }
  }

  const getUniqueEventTemplates = eventTemplates => {
    if (eventTemplates?.length > 0) {
      return _.uniqWith(eventTemplates, (a, b) => a.hour === b.hour && a.minute === b.minute)
    } else {
      return [];
    }
  }

  return (
    <Paper className={classes.root} elevation={24}>
      <Grid container
            justifyContent={"space-between"}>
        <Grid item>
          <PageTitle title={"Resident Treatment Log"} />
        </Grid>
        {isReportPrinter &&
          <Grid item>
            <Button disabled={loading}
                    onClick={() => setViewPrintModal(true)}
                    startIcon={<PictureAsPdfIcon/>}>
              Print
            </Button>
            <PrintTreatmentLogDiaglog open={viewPrintModal}
                                      days={days}
                                      resident={resident}
                                      treatments={treatments}
                                      month={month}
                                      year={year}
                                      onClose={() => setViewPrintModal(false)}/>
          </Grid>
        }
      </Grid>
      <FormGroup>
        <TextField
          onChange={changeYear}
          label="Year"
          fullwidth
          select
          value={year | ""}
        >
          {years &&
            years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          onChange={changeMonth}
          label="Month"
          fullwidth
          select
          value={month | ""}
        >
          {months &&
            months.map((month) => (
              <MenuItem key={month.val} value={month.val}>
                {month.name}
              </MenuItem>
            ))}
        </TextField>
      </FormGroup>

      <Grid container
            className={classes.legendContainer}
      >
        <div className={classes.legendItem}>
          <ScheduleIcon className={classes.legendItem}  />
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Scheduled</Typography>
        </div>
        <div className={classes.legendItem}>
          <CheckCircle color={"secondary"} className={classes.legendItem} />
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Given</Typography>
        </div>
        <div className={classes.legendItem}>
          <FiberManualRecordIcon color={"error"} className={classes.legendItem} />
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Missed</Typography>
        </div>
        <div className={classes.legendItem}>
          <AssignmentLateRounded className={[classes.legendItem, classes.late]} />
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Late</Typography>
        </div>
        <div className={classes.legendItem}>
          <Typography variant={"subtitle2"} className={classes.legendItem}>R</Typography>
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Refused</Typography>
        </div>
        <div className={classes.legendItem}>
          <Typography variant={"subtitle2"} className={classes.legendItem}>H</Typography>
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Hold</Typography>
        </div>
        <div className={classes.legendItem}>
          <Typography variant={"subtitle2"} className={classes.legendItem}>RD</Typography>
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Refused & Destructed</Typography>
        </div>
        <div className={classes.legendItem}>
          <Typography variant={"subtitle2"} className={classes.legendItem}>SA</Typography>
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Self Administered</Typography>
        </div>
        <div className={classes.legendItem}>
          <Typography variant={"subtitle2"} className={classes.legendItem}>NA</Typography>
          <Typography variant={"subtitle2"} className={classes.legendValue}> = Not Administed</Typography>
        </div>
      </Grid>
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Treatment</TableCell>
              <TableCell style={{width:200}}>HR</TableCell>
              {[...Array(days)].map((e, i) => (
                <TableCell className={classes.tableDayColumn}>{i + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!error && loading &&
          <TableLoadingIndicator rows={10} cols={3 + days} bodyOnly={true} />
          }
          {!error && !loading &&
            <TableBody>
              {treatments?.filter(treatment => !treatment?.isPrn)?.map((treatment, i) =>
              <TableRow style={getStripedStyle(i)}>
               <TableCell>{treatment?.name}</TableCell>
                <TableCell style={{width:200}}>
                  {getUniqueEventTemplates(treatment?.eventTemplates)?.map(eventTemplate =>
                    <div className={classes.eventTemplateContainer}>
                      {getHR(eventTemplate, treatment?.frequency)}
                    </div>
                  )}
                </TableCell>
                {[...Array(days)].map((day, index) =>
                  <TableCell className={classes.tableDayColumn}>
                    {getUniqueEventTemplates(treatment?.eventTemplates)?.map(eventTemplate =>
                        <div className={classes.eventTemplateContainer}>
                        {getValue(treatment, eventTemplate, index + 1)}
                        </div>
                      )}
                  </TableCell>
                )}
              </TableRow>
              )}
              {!loading && !error && treatments?.filter(etask => etask?.isPrn)?.length > 0 &&
                <TableRow>
                  <TableCell className={classes.prnAlertContainer} colSpan={days}>
                    <Typography variant={"subtitle1"}>PRNs</Typography>
                  </TableCell>
                </TableRow>
              }
              {treatments?.filter(treatment => treatment?.isPrn)?.map((treatment, i) =>
                <TableRow style={getStripedStyle(i)}>
                  <TableCell>{treatment?.name}</TableCell>
                  <TableCell className={classes.hrHeader}>
                    N/A
                  </TableCell>
                  {[...Array(days)].map((day, index) =>
                    <TableCell className={classes.tableDayColumn}>
                      {getUniqueEventTemplates(treatment?.eventTemplates)?.map(eventTemplate =>
                        <div className={classes.eventTemplateContainer}>
                          {getPrnValue(treatment, index + 1)}
                        </div>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          }
        </Table>
      </TableContainer>
    </Paper>
    )
}
