import React, {useContext} from 'react';
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useHasRole from "../../../hooks/user.role.hook";
import {ResidentContext} from "../../../context/residentcontext";
import {Link as RouterLink} from "react-router-dom";

export const ResidentStatus = () => {
  const {resident} = useContext(ResidentContext);
  const {checkPermission} = useHasRole()
  const canResidentUpdate = checkPermission("RESIDENT_UPDATE");



  return (
    <div>
      {canResidentUpdate &&
        <IconButton size={"small"}
                    component={RouterLink}
                    to={`/residents/${resident?._id}/admissions`}
                    aria-controls="status-menu"
                    aria-haspopup="true">
          <EditIcon fontSize={"small"} />
        </IconButton>
      }
      {resident?.residentStatus?.name}
    </div>
  )
}
