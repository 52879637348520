import React from 'react';
import {Dialog, DialogContent, DialogTitle, IconButton, makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { PrintableCalendarPDF } from './pdf';
import { PDFViewer } from '@react-pdf/renderer';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const CalendarPrintDialog = (
  {
    open,
    onClose,
    selectedDate,
    events,
    resident = null,
    location = null,
    tenant = null
  }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}
            onClose={() => onClose()}
            fullWidth
            maxWidth={"lg"}>
      <DialogTitle
        onClose={() => onClose()}>
        {'Print Calendar'}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers style={{background: '#efefef'}}>
        {moment(selectedDate).isValid() &&
          <PDFViewer style={{width: '100%', height: '70vh'}} >
            <PrintableCalendarPDF events={events}
                                  month={moment(selectedDate).month()}
                                  year={moment(selectedDate).year()}
                                  resident={resident}
                                  tenant={tenant}
                                  location={location}
            />
          </PDFViewer>
        }
      </DialogContent>
    </Dialog>
  )
}
