import React from 'react';
import {Button, Chip, Grid, makeStyles, Paper} from "@material-ui/core";
import {PageTitle} from "../../utils";
import {
  ACTIONS,
  calendarEventSelectedAction,
  calendarSlotSelectedAction, getResidentCalendarEventsAction,
  ResidentCalendarContext,
  ResidentCalendarProvider, updateCalendarFilterTypesAction
} from "./context";
import {FilterListTwoTone} from "@material-ui/icons";
import {calendarEventPropertySetter, invertColor} from "../../calendar/calendar.utils";
import {CalendarTypes} from "./types";
import {SsaCalendar} from "../../calendar";
import {CalendarAgendaEventComponent, CalendarEventComponent, WeekEventComponent} from "../../calendars/events";
import {CreateCalendarEvent} from "./events/create.event";
import {EditCalendarEvent} from "./events/edit.event";
import {RetryMessage} from "../../notification/retry.message";
import {ResidentContext} from "../../../context/residentcontext";
import useHasRole from "../../../hooks/user.role.hook";
import useGlobalNotification from "../../../hooks/notification.hook";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(.5, 0 , 0, 0),
  }
}));

export const ResidentCalendarPage = ({
                                       defaultView="week",
                                       height = "70vh",
                                       navigationComponent = null
                                     }) => {
  const classes = useStyles();
  const { checkPermission } = useHasRole();
  const { addNotification } = useGlobalNotification();
  const canWriteResidentCalendar = checkPermission("RESIDENT_CALENDAR_WRITE");
  const { dispatch, state } = React.useContext(ResidentCalendarContext);
  const [showFilter, setShowFilter] = React.useState(false);
  const { resident } = React.useContext(ResidentContext);


  const calendarTypesFilterChanged = type => {
    updateCalendarFilterTypesAction(dispatch, type);
  }

  const onEventSelected = event => {
    calendarEventSelectedAction(dispatch, event);
  }

  const onSlotSelected = slot => {
    if (canWriteResidentCalendar) {
      calendarSlotSelectedAction(dispatch, slot);
    } else {
      addNotification("You do have have permissions to create resident calendar items", "warning", );
    }
  }

  const getEvents = () => {
    const checkedTypes = state?.calendarTypes?.filter(c => c?.checked);
    if (checkedTypes?.length === state?.calendarTypes?.length) {
      if (state?.viewAllFilter) {
        return state?.events;
      }
      if (!state?.viewAllFilter) {
        return [];
      }
    } else {
      return state?.events?.filter(event =>
        checkedTypes?.findIndex(c => c.name === event?.calendarType) > -1);
    }
  }

  const reloadResidentEvents = async () => {
    await getResidentCalendarEventsAction(dispatch, state?.residentId);
  }

  const onTimeframeNavigated = async ({ date }) => {
    await getResidentCalendarEventsAction(dispatch, state?.residentId, date);
  }

  const onViewChanged = view => {
    dispatch({type: ACTIONS.SET_VIEW, payload: view });
  }

  return (
    <Paper elevation={24}
           className={classes.root}
    >
      {!navigationComponent &&
      <PageTitle title={"Resident Calendar"}>
       <Grid container alignItems={"center"}>
          <Button variant={"outlined"}
                  startIcon={<FilterListTwoTone />}
                  onClick={() => setShowFilter(filter => !filter)}>
            {showFilter ? "Hide":"Show"} Filters
          </Button>
          {state?.calendarTypes?.filter(c => c.checked).map(type =>
            <Chip size="small"
                  onDelete={() => calendarTypesFilterChanged(type)}
                  style={{
                    backgroundColor: type?.color,
                    color: invertColor(type?.color, true)
                  }}
                  className={classes.filterChip}
                  label={type.name} />
          )}
        </Grid>
      </PageTitle>}
      {navigationComponent && <>{navigationComponent}</>}
      <Grid container style={{ paddingTop: '.5rem'}}>
        <Grid item xs={showFilter ? 12: 0} sm={showFilter ? 2: 0}>
          {showFilter && <CalendarTypes />}
        </Grid>
        <Grid item xs={12} sm={showFilter ? 10: 12}>
          {!state?.error &&
          <SsaCalendar events={[...getEvents(), state?.residentBirthdayEvent]}
                       onSlot
                       loadingEvents={state?.loading}
                       defaultView={defaultView}
                       height={height}
                       color={'black'}
                       calendarColor={'black'}
                       scrollToTime={Date.now()}
                       eventPropertySetter={calendarEventPropertySetter}
                       eventSelected={onEventSelected}
                       slotSelected={onSlotSelected}
                       weekEventComponent={WeekEventComponent}
                       customAgendaEventComponent={CalendarAgendaEventComponent}
                       onTimeframeNavigated={(event) => onTimeframeNavigated(event)}
                       view={state.view}
                       onView={onViewChanged}
                       resident={resident}
                       customEventComponent={CalendarEventComponent}/>
          }
          {state?.error &&
          <RetryMessage message={"Error loading resident table"} severity={"error"} onRetry={reloadResidentEvents} />
          }
          <CreateCalendarEvent />
          <EditCalendarEvent />
        </Grid>
      </Grid>
    </Paper>
  )
}

export const ResidentCalendar = ({defaultView = "week"}) => {
  const { resident } = React.useContext(ResidentContext);

  return (
    <ResidentCalendarProvider resident={resident}>
      <ResidentCalendarPage defaultView={defaultView} />
    </ResidentCalendarProvider>
  )
}
